// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bB_k3";
export var caseStudyBackground__lineColor = "bB_k0";
export var caseStudyHead__imageWrapper = "bB_kX";
export var caseStudyProjectsSection = "bB_k4";
export var caseStudyQuote__bgLight = "bB_kY";
export var caseStudyQuote__bgRing = "bB_kZ";
export var caseStudyQuote__bottomVideo = "bB_lZ";
export var caseStudyVideo = "bB_k1";
export var caseStudyVideo__ring = "bB_k2";
export var caseStudy__bgDark = "bB_kV";
export var caseStudy__bgLight = "bB_kT";