import { Link } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'

import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'
import CaseStudyVideoCase from '~/views/CaseStudies/components/CaseStudyVideo'

import useWownerStaticQuery from './useWownerStaticQuery'
import * as containerStyles from './Wowner.module.scss'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

const quotes = {
  client: {
    name: 'Geert-Jan Waasdorp',
    position: 'Co-Founder of Wowner',
  },
  designer: {
    name: 'Inessa',
    position: 'UI/UX Lead at Codica',
  },
  fe: {
    name: 'Eugene',
    position: 'Tech Lead & React expert at Codica',
  },
}

const previewBabelTitle = 'Insurance Progressive Web App'
const previewPmkTitle = 'Online Service Marketplace'

const CaseStudiesWowner = () => {
  const query = useWownerStaticQuery()

  const banner = getImage(fileToImageLikeData(query.banner))
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )
  const solutionDeliveredSeven = getImage(
    fileToImageLikeData(query.solutionDeliveredSeven),
  )
  const videoCaseImage = getImage(fileToImageLikeData(query.videoCaseImage))

  return (
    <MainLayout isTransparent scrollTop>
      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudyHead
          title="Intelligent Job Matching Service for Wowner"
          subtitle="Learn how Codica helped the client create a custom job matching website and save costs on mobile app development with a PWA."
          image={banner}
          classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
          imageTitle="Smart job matching platform for Wowner"
          imageAlt="Intelligent job matching serice for Wowner"
          domain="Recruiting"
          location="Rotterdam, Netherlands"
          timeline="April 2020 - June 2020"
          services={
            <>
              Prototyping,
              <Link
                to="/services/ui-ux-design/"
                className="ml5"
                key="designKey"
              >
                UX/UI design services
              </Link>
              , Front-end, Back-end,
              <Link
                to="/services/progressive-web-apps-development/"
                className="ml5"
                key="pwaDevKey"
              >
                PWA development services
              </Link>
              ,
              <Link
                to="/services/custom-software-development/"
                className="ml5"
                key="customDevKey"
              >
                Custom software development
              </Link>
            </>
          }
          team="2 Frontend Developers, 1 Backend Developer, 1 UX/UI Designer, 1 QA Engineer, 1 Project Manager"
          technologies="React, Rails API, AWS, Redux, Sidekiq, Redis, Gitlab CI"
          isQuote
        />

        <CaseStudyQuote
          quote={[
            {
              text: '“It’s not the only project we brought to life together with the Codica team. I’m glad that they supported our new idea really fast in rather challenging times of the pandemic and lockdown.',
            },
            {
              text: 'The initial idea of Wowner was to create a smart online job search for our local market, helping people working from home to find new relevant jobs. We were looking for a team to help us check the odds and launch in a few weeks, and decided to talk to Dmytro about our new idea.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/clients/client-wowner.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudy__bgDark}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTextLeft="Spring 2020 was a harsh time of Covid-19 pandemic boost in Europe and beyond. Many businesses were affected by this global issue and had to act fast, thinking outside the box to survive and support both their employees and end users. That’s when Wowner project idea came to life, offering intelligent job matching for those who were looking for a new dream job."
        bgTextRight="The main advantage of this solution is a personalized job matching service, based on a user’s set of skills, job preferences, location, and job market opportunities. Unlike an average job search website, the idea behind Wowner is to use a resume matching algorithm for a smart search. It means not only creating a user profile but also suggesting the most corresponding jobs on the market."
        sectionBusiness={[
          {
            label:
              'Quickly launch the product with minimal risks, while this theme is new and hot.',
          },
          {
            label:
              'Build a new career portal for the Netherlands market that would perform well on any device.',
          },
          {
            label:
              'Implement a smart job search based on the user’s skills, location, and preferences. Provide users with a new customized online experience that saves both time and effort.',
          },
          {
            label:
              'Create an intelligent job search system to both analyse the career market and offer relevant job experience.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Developed a ready-to-the-market solution within a short time frame.',
          },
          {
            label:
              'Intelligent job matching project launched, PWA by default. This allows Wowner to cut costs and time on native mobile apps development and have the same effective solution for both iOS and Android devices.',
          },
          {
            label:
              'Implemented smart search system that helps finding the vacancy according to the user’s level of skills and preferences. For example, based on a monthly or hourly rate, remote or onsite work option, and extra conditions like work on weekends, night shifts, etc.',
          },
          {
            label:
              'Created an intelligent job search system that would analyse career chances on the labour market based on user’s data. If some skills lack proficiency, the system offers corresponding courses.',
          },
          {
            label:
              'Integration with the 3rd party APIs, i.e. local job websites databases for relevant job offers and education courses recommendations.',
          },
        ]}
      />

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          quote={[
            {
              text: '“I am happy to be a part of the team that creates such revolutionary solutions like Wowner job matching service. We were working on this project during the first lockdown in the spring of 2020, so it was a bit of a challenge for us. Both the prototypes and design were ready within three weeks, since the customer had pressing deadlines. I’m very proud to see the final result, and that it helps make people’s lives better.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/Inessa.png"
              alt={`${quotes.designer.name}, ${quotes.designer.position} at Codica`}
              title={`${quotes.designer.name}, ${quotes.designer.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.designer.name}
          position={quotes.designer.position}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Do you need to quickly release a cross-platform mobile app?"
        description="Hire our team to launch a Progressive Web App (PWA) and save costs."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <div className="container case-wrapper-lg">
          <CaseStudySolution
            isImageSmall
            image={solutionDeliveredOne}
            title="Mobile-friendly PWA"
            description="We applied our extensive custom software development, and delivered a web product which meets all modern web standards: fast loading, secure, user-friendly, manageable and scalable PWA. With its help, users can easily search the job website for new career opportunities and apply for the matching ones just using a mobile phone."
            alt="Mobile-friendly PWA for the smart job search website"
            imageTitle="Mobile-friendly job matching progressive web app"
          />
          <CaseStudySolution
            isReverse
            isImageSmall
            image={solutionDeliveredTwo}
            title="Full-Scale User Profile"
            description="When registering, a user can either upload a regular CV or create a new one with Wowner. The CV in Wowner app will include the basic user info, a set of skills, and the level of proficiency for every skill. A user can also indicate the desired wages, hourly rates, job preferences, and even extra conditions like weekend work."
            alt="Full-scale user profile on the smart job search website"
            imageTitle="Full-scale user profile on the job search website"
          />
          <CaseStudySolution
            isImageSmall
            image={solutionDeliveredThree}
            title="Smart and Exact Job Search"
            description={
              <>
                Based on the data the users share in their CV, Wowner provides
                top accurate career options search. We used Google Maps API to
                narrow the job search within the needed location and even
                radius. Our
                <Link
                  key="keyCaseSolutionCustomSoft"
                  to="/services/custom-software-development/"
                  style={{ color: '#232323' }}
                  className="mx5"
                >
                  custom software developers
                </Link>
                also integrated 3rd party databases of local job websites with
                their career options.
              </>
            }
            alt="Smart job search on the job match system"
            imageTitle="Smart job search on the job matching service"
          />
          <CaseStudySolution
            isReverse
            isImageSmall
            image={solutionDeliveredFour}
            title="Career Dashboard"
            description="The Career Dashboard includes a personalized User Profile. It comes with a user's job opportunities, labor market score, relevant education, and matching jobs list. Wowner job matching website has a pool of recent jobs for precise skills set and conditions. If the level of some skills does not match the requirements, a user will be offered to take the needed courses."
            alt="Career dashboard on the smart job search website"
            imageTitle="Career dashboard on the job search website"
          />
        </div>
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <div className="container case-wrapper-lg">
          <CaseStudySolution
            isImageSmall
            isTextWhite
            image={solutionDeliveredFive}
            title="The List of Matching Jobs"
            description="Each matching position on this job search website has a submitted date, detailed description, a list of required responsibilities, and opportunity for a user to apply directly from the application. A user can also save the most promising job openings for later revision."
            alt="The list of matching jobs on the smart job search website"
            imageTitle="The list of matching jobs on the job search website"
          />
          <CaseStudySolution
            isReverse
            isImageSmall
            isTextWhite
            image={solutionDeliveredSix}
            title="User’s Skills Passport"
            description="Wowner offers LinkedIn-like profile fields to fill out for future intelligent job matching. It does not require any sensitive data, just some basic personal information sufficient for a regular CV: full name, email, phone number, recent jobs, skills and the level of skills. This Skills Passport can be downloaded as a PDF."
            alt="User’s skills passport on the smart job search website"
            imageTitle="User’s skills passport on the job search website"
          />
          <CaseStudySolution
            isImageSmall
            isTextWhite
            image={solutionDeliveredSeven}
            title="User’s Job Opportunities"
            description="A user can select a job from the List of Matching Jobs and monitor the following data on the information board: a labour market score, expected search time for each vacancy, and general labour market perspective. Such an information board is individual for every vacancy group, i.e. for a Designer, a QA, etc."
            alt="Ser’s job opportunities on the smart job search website"
            imageTitle="User’s job opportunities on the job search website"
          />
        </div>
      </div>

      <div className={containerStyles.caseStudyVideo}>
        <LazyHydrate whenVisible>
          <CaseStudyVideoCase
            title="How it works"
            description="Our goal was to help users find a relevant job matching their certain requirements. For this purpose, we implemented an intelligent search system."
            ringsClass={containerStyles.caseStudyVideo__ring}
            image={videoCaseImage}
            videoLink="https://www.youtube.com/embed/Y6QcDjwURUo"
          />
        </LazyHydrate>

        <div className={containerStyles.caseStudyQuote__bottomVideo}>
          <CaseStudyQuote
            quote={[
              {
                text: '“Glad we had a chance to work on a useful product with interesting functionality. Wowner is an intelligent job matching product in the modern recruitment domain. Yes, it works for a specific job market only, still, it has cool options from the best world practices, like a smart search.',
              },
              {
                text: 'We used Google Map API to show relevant vacancies within indicated locations. What’s more important, we narrowed down the search within an accurate radius, indicated by a user. We also integrated 3rd party databases of local job websites and courses for the broad-ranging vacancies pool.”',
              },
            ]}
            photo={
              <StaticImage
                src="../../../../assets/images/avatars/FE-Eugene.png"
                alt={`${quotes.fe.name}, ${quotes.fe.position} at Codica`}
                title={`${quotes.fe.name}, ${quotes.fe.position}`}
                {...imageProps}
              />
            }
            bgRing={containerStyles.caseStudyQuote__bgRing}
            name={quotes.fe.name}
            position={quotes.fe.position}
          />
        </div>
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudyTech
          technologies={[
            { label: 'React', icon: 'react' },
            { label: 'Rails API', icon: 'ror' },
            { label: 'AWS', icon: 'aws' },
            { label: 'Redux', icon: 'redux' },
            { label: 'Sidekiq', icon: 'sidekiq' },
            { label: 'Redis', icon: 'redis' },
            { label: 'Gitlab CI', icon: 'gitlab' },
            { label: 'TypeScript', icon: 'typescript' },
            { label: 'Material UI', icon: 'material' },
          ]}
          integrations={[
            { label: 'Google APIs', icon: 'googleApi' },
            {
              label: 'Third-party databases',
              icon: 'thirdPartyDatabases',
            },
          ]}
        />
      </div>

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          isBottom
          quote={[
            {
              text: '“Codica’s ability to start fast, professional approach to custom software development, clear communication, result-oriented team, and the final product delivered - all these proved that we made the right choice. We hope for new mutual projects in the future!”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/clients/client-wowner.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s build a great custom product together!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewBabelTitle}
              color="babelColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/babel.png"
                  alt={`Case study: ${previewBabelTitle} | Codica`}
                  title={`Case study: ${previewBabelTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                  width={350}
                />
              }
              url="insurance-progressive-web-application"
            />
            <CaseStudyPreview
              title={previewPmkTitle}
              color="pmkColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/pmk.png"
                  alt={`Case study: ${previewPmkTitle} | Codica`}
                  title={`Case study: ${previewPmkTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="kids-activities-service-marketplace"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesWowner
